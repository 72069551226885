.react-datepicker__day {
  /* White */
  background: #ffffff;
  /* Grey/300 */
  outline: 1px solid #e9ecef; /* use instead of border */
  margin: 1px 1px 0 0;
  height: 42px;
  width: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* Body/Small */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: 0.0625px;
  /* Grey/1000 */
  color: #212529;
}

.react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker__day--selected {
  border-radius: 0;
  /* blue/500 */
  background: #3b82f6;
  /* White */
  color: #ffffff;
}

.react-datepicker__day--selected:hover {
  border-radius: 0;
  /* blue/500 */
  background: #3b82f6;
  /* White */
  color: #ffffff;
}

.react-datepicker__day--outside-month {
  /* Grey/100 */
  background: #f8f9fa;
  /* Grey/600 */
  color: #adb5bd;
}

.react-datepicker__header {
  background-color: white;
}

.react-datepicker__navigation-icon::before {
  border-color: #3e3e3e;
}

.react-datepicker__current-month {
  /* Body/Standard */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* Grey/1000 */
  color: #212529;
}

.react-datepicker__day-name {
  width: 38px;
  /* Body/Tiny */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */
  text-align: center;
  /* Grey/1000 */
  color: #212529;
}

.react-datepicker__header {
  border-bottom: none;
  padding-top: 16px;
}

.react-datepicker__day-names {
  margin-top: 16px;
}

.react-datepicker__navigation--previous {
  top: 16px;
}

.react-datepicker__navigation--next {
  top: 16px;
}

.react-datepicker__month {
  padding: 0 20px 20px;
}

.react-datepicker {
  /* Grey/700 */
  border: 1px solid #868e96;
  border-radius: 4px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: #868e96;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: white;
}

.react-datepicker__close-icon {
  right: 25px;
}

.react-datepicker__close-icon::after {
  background-color: white;
  color: #4b5563;
}

.react-datepicker__day--disabled {
  /* Grey/100 */
  background: #f8f9fa;
  /* Grey/200 */
  color: rgb(229 231 235);
  cursor: not-allowed;
}

.react-datepicker__day--disabled:hover {
  /* Grey/100 */
  background: #f8f9fa;
  /* Grey/200 */
  color: rgb(229 231 235);
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0;
}
